import React from 'react';
import nanoLogo from '../assets/nano-logo.png';

function Nav({ toggle, location }) {
    
    return (
        <div class="bg-primaryBackground relative pt-6 px-4 z-11 lg:mb-20 lg:px-0">
            <nav class="relative flex items-center justify-between w-full" aria-label="Global">
                <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div class="flex items-center justify-between w-full md:w-auto">
                        <a href="/">
                            <span class="sr-only">Workflow</span>
                            <img class="h-lg w-auto lg:h-xl" src={nanoLogo} />
                        </a>
                        <div class="-mr-2 flex items-center md:hidden">
                            <button onClick={()=> toggle()} type="button" class="bg-onPrimaryBgSoft rounded-md p-3 inline-flex items-center justify-center text-gray-400 hover:text-onHoverPrimaryText hover:bg-gray-100 focus:outline-none" id="main-menu" aria-haspopup="true">
                            <span class="sr-only">Open main menu</span>
                            
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                    <a href="/" class="text-xl font-medium text-primaryText hover:text-onHoverPrimaryText">Home</a>

                    <a href="#features" class="text-xl font-medium text-primaryText hover:text-onHoverPrimaryText">Features</a>

                    <a href="#pricing" class="text-xl font-medium text-primaryText hover:text-onHoverPrimaryText">Pricing</a>

                    <a href="#success" class="text-xl font-medium text-primaryText hover:text-onHoverPrimaryText">Success</a>

                    <a href={`${process.env.REACT_APP_FRONT_END}/dashboard`} class="text-lg text-shadow login-button bg-gradient-to-r from-primary to-secondary max-w-full rounded-md p-2 inline-flex items-center justify-center text-primaryText hover:text-onHoverPrimaryText hover:bg-gray-100 focus:outline-none">Dashboard</a>
                </div>
            </nav>
        </div>
    )
};

export default Nav;