import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGa from 'react-ga';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Nav from './components/Nav.js';
import Pricing from './pages/Pricing';

function App(props) {
  const [ isOpen, setIsOpen ] = useState(false);
  
  useEffect(()=> {
    
    if (process.env.NODE_ENV === 'production') {
      console.log('Running production.');
      ReactGa.initialize('UA-164639851-1');
      ReactGa.pageview(window.location.pathname + window.location.search);
    } else if (process.env.NODE_ENV === 'development') {
      console.log('Running local env.');
    } else {
      console.log('Not sure what env were running here')
    }
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className="theme-dark max-w-screen-lg xl:max-w-screen-xl mx-auto overflow-visible">
      <Router>
        <Nav toggle={toggle} />
        <Route exact path="/" component={Pricing} />
      </Router>
    </div> 
  )
};

const mapStateToProps = state => {
  return { status: state.status }
};

// const mapDispatchToProps = dispatch => {
//   return {
//       setUserData: (user) => dispatch(setUserData(user))
//   };
// };

export default connect(mapStateToProps /*, mapDispatchToProps*/)(App);
